<template>
  <div class="HeroHomepage">
    <!-- <Wrap class="xl"> -->
    <Row class="no-gutters">
      <Column class="leftSide" :lg="6">
        <Pic class="linesOverlay" src="../../../assets/lines.svg" />
        <div class="heroBody md">
          <h1 v-if="isBusinessMode">
            Nutolę saulės parkai –
            <br>
            jūsų verslo energijos poreikiams!
          </h1>
          <h1 v-else>
            Įsigykite saulės elektrinę dabar
            <br>
            ir sutaupykite iki 70%
          </h1>
          <h2> {{ bulletpointCurrent }} </h2>
          <!-- <h2>Naudokite saulės energiją, nepriklausomai nuo to, kur gyvenate</h2> -->
          <!-- <ul class="bulletpoints">
              <li
                v-for="(bulletpoint, index) in bulletpoints"
                :key="index">
                {{ bulletpoint }}
              </li>
            </ul> -->
          <Row class="ctas">
            <Column :lg="12" :md="6" :xl="6">
              <Route class="Btn cta arrow invert" :to="routeReserve">
                Įsigyti elektrinę
              </Route>
            </Column>
            <Column :lg="12" :md="6" :xl="6">
              <Route class="Btn cta arrow invert" to="Contact">
                Nemokama konsultacija
              </Route>
            </Column>
          </Row>
          <!-- <strong class="parkAnnouncement">
            <Route :to="{ name: 'ProjectDetails', params: { slug: 'palubeliai' } }">
              PALUBĖLIŲ PARKAS
            </Route> JAU VEIKIA!
          </strong> -->
        </div>
      </Column>
      <Column class="rightSide" :lg="6">
        <div class="background" :class="{ business: isBusinessMode, private: !isBusinessMode }" />
      </Column>
      <!-- <Pic :formats="formats" src="../../../assets/index/heroBusiness.jpg" /> -->
    </Row>
    <!-- </Wrap> -->

    <div v-if="$slots.outside" class="outside">
      <slot name="outside" />
    </div>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    formats: {
      type: Array,
      default: () => [],
    },
    src: String,
  },
  data() {
    return {
      bulletpointsOptionsPrivate: [
        'Naudokite saulės energiją, nepriklausomai nuo to, kur gyvenate',
        'Nesirūpinkite elektrinės įrengimo ir priežiūros darbais',
        'Su valstybės parama saulės elektrinę įsigykite iki 35% pigiau',
      ],
      bulletpointsOptionsBusiness: [
        'Rezervuokite saulės parko dalį ir tapkite atsinaujinančios energijos gamintojais',
      ],
    }
  },
  computed: {
    bulletpointCurrent() {
      const options = this.isBusinessMode
        ? this.bulletpointsOptionsBusiness
        : this.bulletpointsOptionsPrivate

      const randomIndex = Math.floor(Math.random() * options.length)

      return options[randomIndex]
    },
    routeReserve() {
      const CURRENT_PROJECT_ID = 9

      const routeName = this.isAuth
        ? 'SelfReservationAdd'
        : 'SelfReservationAddGuest'

      return { name: routeName, params: { id: CURRENT_PROJECT_ID } }
    },
    ...getters('isBusinessMode'),
  },
}
</script>

<style lang="scss">
.HeroHomepage {
  @include roundSvg('../../../assets/ellipse.svg');
  // align-items: stretch;
  background-color: $black-bis;
  // background-image: url('../../../assets/lines.svg');
  // background-position: bottom left;
  // background-size: 80vw;
  // background-position: bottom center;
  // background-repeat: no-repeat;
  // background-size: cover;
  color: $white;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  overflow: hidden;
  position: relative;
  @keyframes privateToBusiness {
    0% {
      background-image: url('../../../assets/index/heroPrivate.jpg');
    }

    100% {
      background-image: url('../../../assets/index/heroBusiness.jpg');
    }
  }
  @keyframes businessToPrivate {
    0% {
      background-image: url('../../../assets/index/heroBusiness.jpg');
    }

    100% {
      background-image: url('../../../assets/index/heroPrivate.jpg');
    }
  }

  .Row {
    min-height: 50rem;
    @include md {
      min-height: 60rem;
    }
    @include lg {
      min-height: 50rem;
    }
  }

  .Row.ctas {
    min-height: unset;
  }
  // padding: 4.6rem 0.5rem 0;
  // @include md {
  //   padding-bottom: 6rem;
  //   padding-top: 6rem;
  // }
  // @include lg {
  //   padding-bottom: 12rem;
  //   padding-top: 8rem;
  // }
  // @include xl {
  //   padding-top: 10rem;
  // }

  .leftSide {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    @include lg {
      justify-content: center;
    }

    .linesOverlay {
      img {
        bottom: 0;
        position: absolute;
        transform: rotate(150deg);
      }
    }

    // .parkAnnouncement {
    //   color: $black;
    //   font-size: $h5;
    //   margin-top: 2rem;
    //   position: relative;
    //   text-align: center;
    //   @include md {
    //     font-size: $h4;
    //   }

    //   &::before {
    //     background-color: $yellow;
    //     border-radius: $radius-sm;
    //     bottom: -0.5rem;
    //     content: "";
    //     height: 175%;
    //     left: 0;
    //     position: absolute;
    //     width: 100%;
    //     z-index: -1;
    //     @include md {
    //       bottom: -0.75rem;
    //       height: 200%;
    //     }
    //   }

    //   > a {
    //     display: inline-block;
    //   }
    // }
  }

  .heroBody {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 2rem;
    margin-right: 1rem;
    z-index: 1;
    @include lg {
      margin-left: 3rem;
      margin-right: 2rem;
    }

    > h1 {
      font-size: $h25;
      @include sm {
        font-size: $h15;
      }
      @include md {
        font-size: $h1;
      }
      @include lg {
        font-size: $h15;
      }
      @include xl {
        font-size: $h15;
      }
    }

    .bulletpoints {
      font-size: 1.2rem;
      line-height: 2rem;
      opacity: 0.8;
      padding-left: 1rem;
    }

    .cta {
      margin-bottom: 1rem;
      width: 20rem;
    }
  }

  .rightSide {
    position: relative;

    .background {
      background-image: url('../../../assets/index/heroPrivate.jpg');
      // background-blend-mode: multiply;
      // background-color: transparent;
      background-position: right;
      background-size: cover;
      // background-image:
      //   linear-gradient(to bottom, rgba($black, 0.7), rgba($black, 0.2)),
      //   linear-gradient(to bottom, rgba($black, 0.2), rgba($black, 0)),
      //   url('../../../assets/index/heroPrivate.jpg');
      // box-shadow: inset 0 200px 20px -20px rgba($black, 0.7);
      height: 100%;
      // position: absolute;
      // transition: background-image 2s linear;
      // width: 100vw;
      // @include lg {
      //   width: 50vw;
      // }

      &::before {
        background: linear-gradient(to bottom, rgba($black, 0.9), rgba($black, 0.2), transparent);
        // linear-gradient(to bottom, rgba($black, 0.1), rgba($black, 0)),
        // linear-gradient(to bottom, transparent);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      // &.business {
      //   animation: privateToBusiness 1s alternate;
      //   animation-fill-mode: forwards;
      // }

      // &.private {
      //   animation: businessToPrivate 1s alternate;
      //   animation-fill-mode: forwards;
      // }
    }
  }

  h1 {
    font-size: $h2;
    font-weight: $regular;
    @include lg {
      font-size: $h1;
    }
  }

  h2 {
    font-size: $h4;
    font-weight: $regular;
    opacity: 0.8;
  }

  // .Row {
  //   > div {
  //     margin-bottom: 2rem;
  //     @include lg {
  //       margin-bottom: 0;
  //     }

  //     &:last-child {
  //       margin-bottom: 0;
  //     }
  //   }
  // }

  img {
    bottom: 0;
    filter: brightness(0.6);
    left: 0;
    min-height: 100%;
    object-fit: cover;
    object-position: right top;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .outside {
    z-index: 3;
  }

  // &-body {
  //   align-items: center;
  //   display: flex;
  //   flex-grow: 1;
  //   flex-shrink: 0;
  //   height: 100%;
  //   position: relative;
  //   z-index: 2;

  //   > .Wrap {
  //     flex-grow: 1;
  //     flex-shrink: 1;
  //     margin: 0 auto;
  //     padding: 0;
  //     @include xs {
  //       padding: 0 1.5rem;
  //     }
  //     @include xl {
  //       padding: 0 2rem;
  //     }
  //   }
  // }

}
</style>
